import { useContext, useState, useEffect } from "react"
import ButtonLarge4 from "components/elements/ButtonLarge4"
import ButtonMedium4 from "components/elements/ButtonMedium4"
import ContentLarge from "components/elements/ContentLarge"
import ContentNormal from "components/elements/ContentNormal"
import Headline2 from "components/elements/Headline2"
import Headline3 from "components/elements/Headline3"
import ProductLarge from "components/elements/ProductLarge"
import { AppContext } from "contexts/AppContext"
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom"
import { callAPI } from "utils/API"
import { newLineToBreakTag, pointsConvertor } from "utils/general"
import { setupBasketData } from "utils/setup"
import { translate } from "utils/Translate"
import "./HighlightedProductBlock.css"
import ExplainerTextTiny from "components/elements/ExplainerTextTiny"

const jss = createUseStyles({
    HighlightedProductImage: {
        maxHeight: '1000px',
        height: (styleGuide) =>
            !!styleGuide.productInfoAsOverlay ? `46vw` : `calc(46vw + 100px)`,  
        width: '34.2vw'
    },
    ProductText: {},
    "@media screen and (max-width: 1024px)": {
        HighlightedProductImage: {
            height: (styleGuide) =>
            !!styleGuide.productInfoAsOverlay ? `110vw` : `calc(110vw + 100px)`, 
        },
        ProductText:{
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": 3,
            overflow: "hidden",
        }
    },
})

const HighlightedProductBlock = (props) => {
    const navigate = useNavigate()
    const {styleGuide, brandData, accessibleShops } = useContext(AppContext)
    const classes = jss(styleGuide);
    const [product, setProduct] = useState({})
    const [highlightedProducts,setHighlightedProducts] = useState({})
    const [showFullDescription, setShowFullDescription] = useState(false)

    const fetchProductDetails = async () => {
        let HighlightedProduct = await callAPI(`/highlighted-product`, 'GET')
        setHighlightedProducts(HighlightedProduct)
        if (!accessibleShops || !accessibleShops.length) return
        if (!HighlightedProduct?.ProductID|| !HighlightedProduct?.ShopID) return
        let productInfo = HighlightedProduct?.product || [];
        if (productInfo && !!productInfo.length) {
            productInfo[0].images = productInfo[0].images.map(image => {
                productInfo[0].imageSrc = image.imageOriginSource
                return image
            });
            setProduct(productInfo[0])
        }
        return
    }

    const goToCollection = () => {
        if(!product || !product.ProductID || !highlightedProducts?.categoryName) return 

        return navigate(`shop/${highlightedProducts.ShopID || product.ShopID}?category=${highlightedProducts.categoryName.split(' ').join('_')}`)
    }

    const productReaction = async (product) => {
        const ReactionID = product.reaction ? product.reaction.ReactionID : null
        await callAPI("/product/reaction", "POST", { body: { ReactionTargetID: product.ProductID, reactionData: JSON.stringify({ ShopID: product.ShopID }), ...ReactionID && { ReactionID } } });
        fetchProductDetails()
        // await setupBasketData({ userData, setBasket })
    }

    useEffect(()=>fetchProductDetails(),[brandData, accessibleShops])
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    return !brandData || !product?.ProductID ? null : <>
        <div id="HighlightedProductBlock" className={`HighlightedProductBlock ${props.className || ''}`} >
            <div>
                <ProductLarge
                    key={product.ProductID}
                    productID={product.ProductID}
                    showTag={product.enableDiscount ? true : false}
                    tagOptions={{
                        fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${ pointsConvertor(brandData,product.productPointComputed - product.discountPointComputed, pointSuffix)}`,
                        showClose: false,
                    }}
                    className={`highlightedProduct ${classes.HighlightedProductImage}`}
                    tagStyles={{ borderRadius: 8 }}
                    points={(product.enableMulti && product.minCount > 0)
                          ?
                          `${product.discountPointComputed
                            ? product.minCount * product.discountPointComputed
                            : product.minCount * product.productPointComputed} `
                          :
                          `${product.discountPointComputed
                            ? product.discountPointComputed
                            : product.productPointComputed}`
                        }
                    liked={product.reaction && product.reaction.ReactionID ? true : false}
                    text2={product.elementTitle && product.elementTitle.length > 20 ? `${product.elementTitle.slice(0, 20)}...` : product.elementTitle}
                    text1={product.manufacturer}
                    wishlistCallback={() => productReaction(product)}
                    imageSrc={`url(${highlightedProducts.productImageSrc|| product.imageSrc})`}
                    activityName='home'
                />
            </div>
            <div className="highlightedProductText">
                { !!highlightedProducts?.title && <div><Headline2 translationKey="_HIGHLIGHTED_PRODUCT_TITLE_" fallbackText={highlightedProducts.title} /></div>}
                { !!highlightedProducts?.content && <div>
                    <ContentLarge className={!showFullDescription ? classes.ProductText : ""} translationKey="_HIGHLIGHTED_PRODUCT_CONTENT_" fallbackText={newLineToBreakTag(highlightedProducts.content)} />
                    <br/>
                    {!showFullDescription 
                        ? <ExplainerTextTiny style={{fontWeight: 700}} onClick={() => setShowFullDescription(!showFullDescription)} className="mt-4 d-lg-none d-sm-inline-block" translationKey="_READ_MORE_BUTTON_TEXT_" fallbackText="Læs mere" /> 
                        : <ExplainerTextTiny style={{fontWeight: 700}} onClick={() => setShowFullDescription(!showFullDescription)} className="mt-4 d-lg-none d-sm-inline-block" translationKey="_READ_LESS_BUTTON_TEXT_" fallbackText="Læs mindre" /> 
                    }
                </div>}
                { !!highlightedProducts?.categoryName && <div>
                    <ButtonLarge4
                        style={{ margin: "auto" }}
                        translationKey="_HOME_HIGHLIGHTED_PRODUCT_BLOCK_BUTTON_CAPTION_"
                        onClick={goToCollection}
                    >
                        {highlightedProducts?.categoryName || 'Se produkter'}
                    </ButtonLarge4>
                    </div>
                }
            </div>
        </div>
    </>
}

export default HighlightedProductBlock


