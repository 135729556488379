import ButtonLarge1 from "components/elements/ButtonLarge1"
import ContentNormal from "components/elements/ContentNormal"
import Headline4 from "components/elements/Headline4"
import { AppContext } from "contexts/AppContext"
import { useContext } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import Activity from "utils/Activity"
import './Footer.css'

const BasketFaqBlock = (props) => {
    const {styleGuide} = useContext(AppContext)
    const navigate = useNavigate()
    
    return <div className="basket-faq-block"
    style={{display: 'flex', flexDirection: 'column', paddingTop: 60, paddingBottom: 60, backgroundColor: styleGuide.color7}}>
        <Headline4 
            translationKey="_BASKET_FAQ_BLOCK_TITLE_"
            fallbackText="Mangler du svar på et spørgsmål?"
            style={{
                color: styleGuide.color1
            }}
        />
            
        <div className="basket-faq-block-content">
            <ContentNormal 
                translationKey="_BASKET_FAQ_BLOCK_TEXT_" 
                fallbackText={`Hvis du mangler svar på et spørgsmål så findes svaret måske allerede under vores FAQ. 
                Klik på knappen for at åbne FAQ.`}
                style={{color: styleGuide.color1, flex: 0.7}}
                /> 
            <ButtonLarge1 translationKey="_FAQ_" fallbackText="FAQ" onClick={() => {
                Activity({activityType: 'click',activityName: 'footer',activityArgument: 'BasketFAQ',activityArgumentID: ''})
                navigate('/help')}} />
        </div>
    </div>
}

export default BasketFaqBlock