import Translate from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

const ContentNormal = (props) => {
    const { styleGuide } = useContext(AppContext)
    const contentNormalStyle = {
      fontFamily: styleGuide.secondaryFontFamily,
      fontWeight: styleGuide.contentNormalFontWeight,
      fontSize: styleGuide.contentNormalFontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide.contentNormalLineHeight,
      color: styleGuide.color7
    }
  
    return (
      <span onClick={props.onClick} style={{...contentNormalStyle, ...props.style}} className={props.className}>
        <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>
      </span>
    )
}

export default ContentNormal;