import moment from "moment";

function serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}

const brandslug = window.location.hostname.replace('www.','').split('.')[0].toLowerCase();
const customDomain = window.location.hostname.replace('www.','');
const KLAVIYO_PUBLIC_API_KEY = {
    "tryg": "TfcYhm",
    "santander": "WwJBDK",
    "ok": "XpftEg"
}

export const callAPI = (path, method = 'GET', params = {body: {}, query: {}}, accessToken) => {
    if(path) {
        const requestBody = {...params.body, brandslug, customDomain}
        const token = accessToken ? accessToken : getSessionToken()
        let endpoint = `/api/${path}`.replaceAll('//','/') // --handle '/' prefix if supplied
        endpoint += '?'+serialize({...params.query, brandslug, customDomain});
        const fetchOptions = {
            method,
            // withCredentials: true,
            // credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        if(method.trim().toUpperCase() !== 'GET' && method.toUpperCase() !== 'HEAD') {
            fetchOptions.body = JSON.stringify(requestBody)
        }
        return fetch(endpoint, fetchOptions)
        .then(response => {
            // check for 401 and logout the user
            if(response.status === 401){     
                localStorage.clear()          
                window.location.pathname = '/logout'
            }
            return response.json()
        })
        .then(async responseJSON => responseJSON)
        .catch(error => {
            console.error(error)
            return error;
        })
    } else {
        return `Path is empty`
    }
}

export const getSessionToken = () => {
    return localStorage.getItem(`accessToken`);
}

export const sessionGrant = async (userIdentifier) => {
    try {
        if(userIdentifier) {
            const requestBody = {userIdentifier,brandslug, customDomain}
            const fetchResponse = await fetch(`/api/sessions/grant`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),
            }
            // ).then(response =>  (response.ok) ? response.json() : null);
            ).then(response =>  response.json());
            return fetchResponse
        }
    } catch (err) {
        console.error(err)
    }
}

export const sessionAccess = async (accessCode, userIdentifier) => {
    try {
        if(accessCode) {
            const requestBody = {
                accessCode : accessCode,
                deviceTimezone : Date().toString().match(/\((.*)\)/).pop(),
                deviceLanguage : window.navigator.language,
                userIdentifier,
                brandslug,
                customDomain
            }
            const fetchResponse = await fetch(`/api/sessions/access`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),
            }
            // ).then(response =>  (response.ok) ? response.json() : null);
            ).then(response =>  response.json());
            return fetchResponse
        }
    } catch (err) {
        console.error(err)
    }
}

export const callKlaviyoClientAPI = async (user, eventName, properties, value) => {
    const domains = window.location.hostname.split('.').filter(domain => ["localhost","dev","alpha"].includes(domain.toLowerCase()))
    const isProduction = !domains.length
    if(!isProduction){
        return null
    }
    if(!user?.KlaviyoProfileID){
        return null
    }
    const PUBLIC_API_KEY=KLAVIYO_PUBLIC_API_KEY[brandslug]
    if(!PUBLIC_API_KEY){
        return
    }
    const endpoint = `https://a.klaviyo.com/client/events/?company_id=${PUBLIC_API_KEY}`

    const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          revision: '2024-02-15',
          'content-type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                type: 'event',
                attributes: {
                    properties,
                    time: new Date(),
                    value,
                    value_currency: 'DKK',
                    unique_id: `${properties.ProductID || properties.ConfirmationID}_${moment().format('DD-MM-YYYY')}`,
                    metric: {data: {type: 'metric', attributes: {name: eventName}}},
                    profile: {
                        data: {
                            type: 'profile',
                            id: user.KlaviyoProfileID,
                            attributes: {
                                email: user.userEmail || null,
                                phone_number: user.userPhone || null,
                                external_id: user.UserID
                            }
                        }
                    }
                }
            }
        })
    }
    const response = await fetch(endpoint, options)
    return response
}