import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Translate, { translate } from "utils/Translate";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import { AppContext } from "contexts/AppContext";
import ExplainerText from "components/elements/ExplainerText";
import Headline5 from "components/elements/Headline5";
import { useNavigate } from "react-router-dom";
import './Footer.css'
import Activity from "utils/Activity";

const PersonalShopper = () => {
  const { styleGuide,brandData } = useContext(AppContext);
  const navigate = useNavigate()
  const textColor = brandData?.features?.personalShopperTextColorBlack ? '#000' : styleGuide.color1
  return (
    <>
      <Row
        style={{
          backgroundColor:styleGuide.color14,
          color: styleGuide.color1,
        }}
        className="footerTop"
      >
        <Col md={6}>
          <Row
            style={{
              marginBottom: 20,
            }}
          >
            <Headline5 style={{ color: textColor }} translationKey="_PERSONAL_SHOPPER_CAPTION_">
              Få hjælp af vores Personal Shopper
            </Headline5>
          </Row>
          <Row>
            <ExplainerText
              translationKey="_PERSONAL_SHOPPER_TEXT_"
              style={{ color: textColor }}
            >
              Hvis du søger et bestemt produkt, eller der er noget du ikke kan
              finde, kan vi bestille det til dig
            </ExplainerText>
          </Row>
        </Col>
        <Col
          md={6}
          style={{
            display: "flex",
            alignContent: "center",
          }}
          className="footerBtnCol"
        >
          <ButtonLarge1
            style={{ fontFamily: styleGuide.secondaryFontFamily }}
            onClick={() => {
              Activity({activityType: 'click',activityName: 'footer',activityArgument: 'PersonalShopper',activityArgumentID: ''})
              navigate("/personalShopper")}}
            fallbackText="Kontakt os"
            translationKey="_CONTACT_US_"
          />
        </Col>
      </Row>
    </>
  );
};

const FooterTop = {
  paddingLeft: 100,
  paddingRight: 100,
  paddingTop: 60,
  paddingBottom: 60,
};

export default PersonalShopper;
