import { Navigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react'
import { callAPI } from "utils/API";
import { AppContext } from "contexts/AppContext";
import CircularIndeterminate from "components/blocks/progressBar/progressBar";

const SSO = () => {
    const location = useLocation()
    const {setUserData} = useContext(AppContext)
    const token = location.search ? new URLSearchParams(location.search).get('token') : null
    const [user, setUser] = useState(null)
    const [redirectLogin, setRedirectLogin] = useState(false)
    console.log('token', token)

    const validateSession = async() => {
        let sessionResponse = await callAPI(`/sessions/validate`, 'POST',{body:{token}})
        if(sessionResponse && sessionResponse.accessToken){
            localStorage.clear()
            localStorage.setItem('accessToken',sessionResponse.accessToken)
            setUserData(sessionResponse.user)
            setUser(sessionResponse.user)
        }else{
            localStorage.clear()
            setUserData(null)
            setRedirectLogin(true)
        }
    }

    useEffect(() => {
        validateSession()
    },[])

    return redirectLogin ? 
        <Navigate to={'/login'} replace={true} /> : 
        user && user.UserID ? 
        <Navigate to={'/'} replace={true} /> : 
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
            <CircularIndeterminate size={100}/>
        </div>
}

export default SSO;