import { useContext, useState, useEffect } from "react";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import ContentLarge from "components/elements/ContentLarge";
import Headline2 from "components/elements/Headline2";
import { AppContext } from "contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { callAPI } from "utils/API";
import { newLineToBreakTag } from "utils/general";
import "./TaxCampaignHighlightBlock.css";
import Image from "components/elements/Image";
import ButtonMedium1 from "components/elements/ButtonMedium1";

const TaxCampaignHighlightBlock = (props) => {
  const navigate = useNavigate();
  const { styleGuide, brandData, langCode } = useContext(AppContext);
  const [taxCampaignHighlight, setTaxCampaignHighlight] = useState({});

  const fetchHighlight = async () => {
    let TaxCampaignHighlight = await callAPI(`/tax-campaign-highlight`, "GET");
    setTaxCampaignHighlight(TaxCampaignHighlight);
  };

  const goToCollection = () => {
    if (!taxCampaignHighlight || !taxCampaignHighlight.TaxCampaignPostID)
      return;

    return navigate(`postcampaign/${taxCampaignHighlight.TaxCampaignPostID}`);
  };

  useEffect(() => fetchHighlight(), [brandData]);

  return !brandData || !taxCampaignHighlight ? null : (
    <div className={props.className}>
      <Image
        className="taxCampaignHighlight "
        src={`url(${taxCampaignHighlight.imageUrl})`}
        style={{
          width: "100%",
          objectFit: "cover",
        }}
      >
        <div className="taxCampaignHighlightText">
          {!!taxCampaignHighlight?.title && (
            <div>
              <Headline2
                translationKey="_TAX_CAMPAIGN_HIGHLIGHT_TITLE_"
                style={{
                  color: taxCampaignHighlight.textColor === "black"
                    ? styleGuide.color18
                    : styleGuide.color1,
                  fontSize: styleGuide.headline1FontSize,
                  fontFamily: styleGuide.fontFamily
                }}
                fallbackText={
                  !!taxCampaignHighlight &&
                  !!taxCampaignHighlight.translations &&
                  !!taxCampaignHighlight.translations.length
                    ? taxCampaignHighlight.translations.find(
                        (translation) => translation.languageKey === langCode
                      ).title
                    : !!taxCampaignHighlight.title
                }
              />
            </div>
          )}
          {!!taxCampaignHighlight?.description && (
            <ContentLarge
              translationKey="_TAX_CAMPAIGN_HIGHLIGHT_CONTENT_"
              style={{
                color: taxCampaignHighlight.textColor === "black"
                    ? styleGuide.color18
                    : styleGuide.color1,
                fontSize: styleGuide.headline5FontSize,
                fontFamily: styleGuide.fontFamily
              }}
              fallbackText={newLineToBreakTag(
                !!taxCampaignHighlight &&
                  !!taxCampaignHighlight.translations &&
                  !!taxCampaignHighlight.translations.length
                  ? taxCampaignHighlight.translations.find(
                      (translation) => translation.languageKey === langCode
                    ).description
                  : !!taxCampaignHighlight.description
              )}
            />
          )}
          {!!taxCampaignHighlight?.TaxCampaignPostID && (
            <div>
              <div className="countDownButtonM">
                <ButtonMedium1
                  onMedium1
                  showIcon={false}
                  style={{ maxWidth: "max-content" }}
                  translationKey="__TAX_CAMPAIGN_HIGHLIGHT_BLOCK_BUTTON_CAPTION_"
                  onClick={goToCollection}
                >
                  Læs mere
                </ButtonMedium1>
              </div>
              <div className="countDownButtonL">
                <ButtonLarge1
                  style={{ maxWidth: "max-content" }}
                  translationKey="__TAX_CAMPAIGN_HIGHLIGHT_BLOCK_BUTTON_CAPTION_"
                  onClick={goToCollection}
                >
                  Læs mere
                </ButtonLarge1>
              </div>
            </div>
          )}
        </div>
      </Image>
    </div>
  );
};

export default TaxCampaignHighlightBlock;
