import { Routes, Route, useParams } from "react-router-dom";
import Page from "components/layouts/Page";
import NoMatch from "components/elements/NoMatch";
import CheckAuth from "utils/checkAuth";
import Home from "modules/home/Home";
import Login from "modules/session/Login";
import PostList from "modules/post/PostList";
import Example from "modules/example/Example";
import ExampleBlocks from "modules/example/ExampleBlocks";
import Logout from "modules/session/Logout";
import PostDetails from "modules/post/PostDetails";
import Shop from "modules/shop/Shop";
import Product from "modules/product/Product";
import Basket from "modules/basket/Basket";
import Profile from "modules/profile/Profile";
import Help from "modules/help/Help";
import Search from "modules/search/Search";
import TermsOfTrade from "modules/termsOfTrade/TermsOfTrade";
import CookiePage from "modules/cookie/CookiePage";
import PersonalShopper from "modules/personalShopper/PersonalShopper";
import Team from "modules/team/Team";
import HOF from "modules/hof/hof";
import SSO from "modules/session/SSO";
import CompetitionDetails from "modules/competition/competitionDetails";
import ActivationDetails from "modules/activation/activationDetails";
import Report from "modules/Report/Report";
import Register from "modules/register/Register"
import UnderMaintenance from "modules/session/underMaintenance";
import ChristmasCalendarDetail from "modules/christmasCalendar/ChristmasCalendarDetail";
import Package from "modules/package/Package";
import TagManager from "react-gtm-module";
import { useContext, useEffect } from "react";
import { AppContext } from "contexts/AppContext";

const UpgraderClubRouter = () => {
    const {brandData} = useContext(AppContext)
    const {disableShop, disableNews, disableHome, disableTaxCampaignPosts} = brandData?.features
    const homeComponent = () => {
        if(!disableHome) return <Home />
        if(!disableShop) return <Shop />
        if(!disableNews) return <PostList />
        return <Profile />
    }

    const homeText = () => {
        if(!disableHome) return "home"
        if(!disableShop) return "shop"
        if(!disableNews) return "posts"
        return "profile"
    }

    return (
        <Routes>
            <Route path="maintenance" element={<PageWithGTMApplied component={<UnderMaintenance/>} pageName="maintenance" paramName="" />}/>
            <Route path="register" element={<PageWithGTMApplied component={<Register/>} pageName="register" paramName="" />}/>
            <Route path="login" element={<PageWithGTMApplied component={<Login/>} pageName="login" paramName="" />} />
            <Route path="sso" element={<PageWithGTMApplied component={<SSO/>} pageName="sso" paramName="" />} />
            <Route exact path="cookie-policy" element={<PageWithGTMApplied component={<CookiePage/>} pageName="cookie-policy" paramName="" />}/>
            <Route element={<CheckAuth><Page /></CheckAuth>}>
                <Route exact path="/" element={<PageWithGTMApplied component={homeComponent()} pageName={homeText()} paramName="" />} />
                <Route exact path="logout" element={<PageWithGTMApplied component={<Logout/>} pageName="logout" paramName="" />} />
                <Route exact path="activationDetails" element={<PageWithGTMApplied component={<ActivationDetails/>} pageName="activationDetails" paramName="" />}/>
                <Route exact path="competition/:CompetitionID" element={<PageWithGTMApplied component={<CompetitionDetails/>} pageName="competition" paramName="CompetitionID" />}/>
                <Route exact path="christmas-calendar/:CompetitionID" element={<PageWithGTMApplied component={<ChristmasCalendarDetail/>} pageName="christmas-calendar" paramName="CompetitionID" />}/>
                { 
                    !!disableNews ? null : <>
                    <Route exact path="posts" element={<PageWithGTMApplied component={<PostList/>} pageName="posts" paramName="" />}/>
                    <Route exact path="competitions" element={<PageWithGTMApplied component={<PostList postType=""/>} pageName="competitions" paramName="" />} />
                    <Route exact path="posts/:BlogID" element={<PageWithGTMApplied component={<PostList/>} pageName="posts" paramName="BlogID" />}/>
                    <Route exact path="post/:PostID" element={<PageWithGTMApplied component={<PostDetails/>} pageName="post" paramName="PostID" />}/>
                    </>
                }
                {
                    !!disableTaxCampaignPosts ? null : <>
                    <Route exact path="postcampaigns" element={<PageWithGTMApplied component={<PostList postType="taxCampaign"/>} pageName="posts" paramName="" />}/>
                    <Route exact path="postcampaigns/:BlogID" element={<PageWithGTMApplied component={<PostList postType="taxCampaign"/>} pageName="posts" paramName="BlogID" />}/>
                    <Route exact path="postcampaign/:PostID" element={<PageWithGTMApplied component={<PostDetails postType="taxCampaign"/>} pageName="post" paramName="PostID" />}/>
                    </>
                }
                {
                    !!disableShop ? null : <>
                    <Route exact path="shop/:ShopID" element={<PageWithGTMApplied component={<Shop/>} pageName="shop" paramName="ShopID" />}/>
                    <Route exact path="product/:ProductID" element={<PageWithGTMApplied component={<Product/>} pageName="product" paramName="ProductID" />}/>
                    <Route exact path="package/:PackageID" element={<PageWithGTMApplied component={<Package/>} pageName="package" paramName="PackageID" />}/>
                    <Route exact path="wishlist" element={<PageWithGTMApplied component={<Shop/>} pageName="wishlist" paramName="" />}/>
                    <Route exact path="basket" element={<PageWithGTMApplied component={<Basket/>} pageName="basket" paramName="" />}/>                    
                    </>
                }
                <Route exact path="profile" element={<PageWithGTMApplied component={<Profile/>} pageName="profile" paramName="" />}/>
                <Route exact path="profile/:Subpage" element={<PageWithGTMApplied component={<Profile/>} pageName="profileSettings" paramName="" />}/>
                <Route exact path="help" element={<PageWithGTMApplied component={<Help/>} pageName="help" paramName="" />}/>
                <Route exact path="search" element={<PageWithGTMApplied component={<Search/>} pageName="search" paramName="" />}/>
                <Route exact path="terms" element={<PageWithGTMApplied component={<TermsOfTrade/>} pageName="terms" paramName="" />} />  
                <Route exact path="team" element={<PageWithGTMApplied component={<Team/>} pageName="team" paramName=""/>}/>
                <Route exact path="personalShopper" element={<PageWithGTMApplied component={<PersonalShopper/>} pageName="personalShopper" paramName="" />}/>
                <Route exact path="halloffame/:HalloffameID" element={<PageWithGTMApplied component={<HOF/>} pageName="halloffame" paramName="HalloffameID" />}/>                
                <Route exact path="indberetning" element={<PageWithGTMApplied component={<Report/>} pageName="indberetning" paramName="" />}/>
            </Route>

            {/* example pages for demo purpose */}
            <Route exact path="example" element={<PageWithGTMApplied component={<Example/>} pageName="" paramName="" />} />
            <Route exact path="example-blocks" element={<ExampleBlocks />} />
            <Route exact path="example-product" element={<Product />} />

            <Route path="*" element={<NoMatch />} />
        </Routes>
    )
}

export default UpgraderClubRouter

const PageWithGTMApplied = (props) => {
    const {pageName, paramName, component } = props
    const params = useParams()

    useEffect(()=>{
        if(!pageName) return

        TagManager.dataLayer({
            dataLayer: {
                event: 'page_view',
                page: !!paramName ? `${pageName}/${params[paramName]}` : pageName
            },
        })
        // console.log(!!paramName ? `${pageName}/${params[paramName]}` : pageName)    
    },[pageName, paramName])

    return <>{component}</>
}