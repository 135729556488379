import Headline3 from "components/elements/Headline3";
import { AppContext } from "contexts/AppContext";
import React, { useContext, useEffect, useState } from "react";
import Image from "components/elements/Image";
import { callAPI } from "utils/API";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Headline4 from "components/elements/Headline4";
import Activity from "utils/Activity";

const HOF = () => {
    const { styleGuide, accessibleHOFs, brandData,langCode } = useContext(AppContext);
    const [selectedHOF, setSelectedHOF] = useState(null);
    const [HOFs, setHOFs] = useState([]);
    const navigate = useNavigate();

    const { HalloffameID } = useParams();

    if(HalloffameID && accessibleHOFs && accessibleHOFs.length) {
        let hofIDs = accessibleHOFs.map((hof) => hof.HalloffameID)
        if(!hofIDs.includes(Number(HalloffameID))) {
            navigate('/')
        }
    }  

    let { hofTitle, hofDescription } = brandData && brandData.features
    const title = hofTitle && typeof hofTitle === 'object' ?
        hofTitle.hasOwnProperty(langCode) ? hofTitle[langCode] : 'Konkurrencer'
        : hofTitle ? hofTitle : 'Konkurrencer'
    const description = hofDescription && typeof hofDescription === 'object' ?
        hofDescription.hasOwnProperty(langCode) ? hofDescription[langCode] : ''
        : hofDescription ? hofDescription : ''
    const bannerImageHeight = 560;
    const hofBannerImage =
        brandData.features.hofBannerImageURL || "https://i.imgur.com/NPB3IhS.png";
    const imageHeight = brandData.features.hofImageHeight
        ? `${brandData.features.hofImageHeight}px`
        : bannerImageHeight;
    const hofTextColor = brandData.features.hofTextColorBlack
        ? styleGuide.color18
        : styleGuide.color1;
    const getHOF = async () => {
        const response = await callAPI(`halloffame/${HalloffameID}`);
        if (response && response.length) {
            setHOFs(response);
        } else {
            setHOFs([]);
        }
    };

    useEffect(() => {
        setSelectedHOF(Number(HalloffameID));
        getHOF();
    }, [HalloffameID]);

    useEffect(() => {
        document.querySelector(".content-wrapper").classList.add("position-relative")
        return () => document.querySelector(".content-wrapper").classList.remove("position-relative")
    },[])

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", height: imageHeight, width: "100%", maxHeight: styleGuide.bannerMaxHeight}}>
                {!!hofBannerImage && (
                    <Image
                        src={`url(${hofBannerImage})`}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 0,
                            height: styleGuide.bannerMaxHeight,
                            width: "100%",
                            maxHeight: styleGuide.bannerMaxHeight,
                            backgroundPosition:"center",
                            backgroundSize:"cover"
                        }}
                    ></Image>
                )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: 100, marginRight: 24 }}>
                <div style={{ position: "absolute",top: bannerImageHeight / 10 }}>
                    <div className="personalShopperBannerTitle">
                        <Headline3 style={{fontSize: 60, lineHeight: "70px", color: hofTextColor,}}>
                            {title}
                        </Headline3>
                        <p className="personalShopperDescription" style={{ marginTop: "20px", color: hofTextColor }}>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
            {accessibleHOFs.length > 1 && (
                <div style={{ top: styleGuide.bannerMaxHeight, width: "100%", background: styleGuide.color18, display: "flex", padding: "20px", height: 50, justifyContent: "center", position: "absolute", left: 0 }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: 50, alignItems: "center" }}>
                        {accessibleHOFs.map((hof) => {
                            return (
    <div
                                    key={hof.HalloffameID}
                                    style={{ color: "#ffffff", cursor: "pointer", fontWeight: selectedHOF === hof.HalloffameID ? "bold" : "normal",textTransform: "capitalize" }}
                                    onClick={() => { 
                                        Activity({activityType: 'click',activityName: 'halloffame',activityArgument: 'Halloffame',activityArgumentID: hof.HalloffameID})
                                        navigate(`/halloffame/${hof.HalloffameID}`) }} >
                                    {hof.moduleName}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <div>
                {!!HOFs && HOFs.length ? <div xs="12" style={{marginTop: 100}}>
                    {HOFs.map((hof, index) => (
                        <HallOfFameElements key={index} index={index} hofData={hof} />
                    ))}
                </div> 
                : <div className='text-center align-middle'>
                    <Headline4 translationKey="_NO_HALL_OF_FAME_" fallbackText="Du har ingen aktive konkurrencer"/>
              </div>}
            </div>
        </div>
    );
};

export const HallOfFameElements = (props) => {
    const { rank, elementImageURL, elementTitle, ruleComputedValue, userCompany } = props.hofData;
    const { styleGuide, brandData } = useContext(AppContext);

    return (
        <Row className="wg-row bg-col" style={{ padding: "15px", backgroundColor: props.index % 2 === 1 ? styleGuide.color2 : styleGuide.color1 }}>
            <Col md="1" className="text-right align-middle pt-3">
                {rank}
            </Col>
            <Col md="2" className="text-center">
                <Image 
                    src={`url(${elementImageURL || "https://static.upgrader.club/img/userthumb.png"})`}
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                        objectFit: "cover",
                    }}
                    className="userImage hofImage"
                    alt={elementTitle}
                />
            </Col>
            <Col className="text-left pt-3">{elementTitle}</Col>
            {brandData.features && brandData.features.hofCompanyName && (
                <Col className="text-left pt-3">{userCompany}</Col>
            )}
            <Col md="2" className="text-right pt-3">
                {ruleComputedValue}
            </Col>
        </Row>
    );
};

export default HOF;