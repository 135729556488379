import ContentNormal from "components/elements/ContentNormal"
import Image from "components/elements/Image"
import { AppContext } from "contexts/AppContext"
import React,{ useContext, useEffect, useState, useRef } from "react"
import { useLocation, useParams } from "react-router-dom"
import "./Shop.css"
import { callAPI } from "utils/API";
import { Grid } from "@mui/material"
import ProductNormal from "components/elements/ProductNormal"
import { translate } from "utils/Translate";
import CustomSelect from "components/elements/CustomSelect";
import MultipleSelectCheckmarks from "components/elements/MultipleSelectCheckmarks"
import CustomInput from "components/elements/CustomInput"
import Search from "components/icons/Search"
import Playbutton from "components/icons/Playbutton"
import Pausebutton from "components/icons/Pausebutton"
import CaptionText from "components/elements/CaptionText"
import LabelTag2 from "components/elements/LabelTag2"
import ButtonMedium4 from "components/elements/ButtonMedium4"
import Headline5 from "components/elements/Headline5"
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import ArrowTopWithCircle from "components/icons/ArrowTopWithCircle"
import FilterIcon from "components/icons/FilterIcon"
import { Col } from "react-bootstrap";
import CircularIndeterminate from 'components/blocks/progressBar/progressBar'
import Activity from "utils/Activity"
import _ from 'lodash'
import { pointsConvertor } from "utils/general"
import ScrollButton from "components/elements/ScrollButton";


const jss = createUseStyles({
    seeMoreButton: {
    position: "relative",
    },
    shopHeadline: {
        fontWeight: (styleGuide) => styleGuide.headline1FontWeight,
        fontSize: (styleGuide) => styleGuide.headline1FontSize,
        lineHeight: (styleGuide) => styleGuide.headline1LineHeight
    },
    shopContent: {
        fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
        fontWeight: (styleGuide) => styleGuide.contentNormalFontWeight,
        fontSize: (styleGuide) => styleGuide.contentNormalFontSize,
        lineHeight: (styleGuide) => styleGuide.contentNormalLineHeight,
    },
    "@media screen and (min-width: 700px) and (max-width:1023px)": {
        shopHeadline: {
        fontWeight: (styleGuide) => styleGuide.headline2FontWeight,
        fontSize: (styleGuide) => styleGuide.headline2FontSize,
        lineHeight: (styleGuide) => styleGuide.headline2LineHeight
    }
    },
    "@media screen and (max-width:700px)": {
        shopHeadline: {
        fontWeight: (styleGuide) => styleGuide.headline3FontWeight,
        fontSize: (styleGuide) => styleGuide.headline3FontSize,
        lineHeight: (styleGuide) => styleGuide.headline3LineHeight
        }
    },
    "@media screen and (min-width: 1024px)": {
        seeMoreButton: {
        position: "absolute",
        marginTop: 0,
        top: '100px',
        right: '100px',
        }
    },
});

const Shop = (props) => {
    const { styleGuide, accessibleShops, brandData, userData, basket, defaultTheme, langCode } = useContext(AppContext)
    const classes = jss(styleGuide);
    const [filtersApplied, setfiltersApplied] = useState(false)
    const [scrollTargets, setScrollTargets] = useState([]);
    const { ShopID } = useParams()
    const location = useLocation();
    const navigate = useNavigate()
    const queryString = new URLSearchParams(location.search)
    let filterCategoryName = location.search
      ? queryString.get("category") &&
        queryString.get("category").includes("_")
        ? queryString
            .get("category")
            .split("_")
            .join(" ")
        : queryString.get("category")
      : null; 

    let shopIDs = accessibleShops.map((shop) => shop.ShopID )

    if(brandData?.features?.disableHome && !ShopID) {
        navigate(`/shop/${shopIDs[0]}`)
    }
    if(ShopID && accessibleShops?.length) {
        if(!shopIDs.includes(Number(ShopID))) {
            navigate('/')
        }
    }  
    // if(brandData?.features?.disableHome || (ShopID && accessibleShops && accessibleShops.length)) {
    //     let shopIDs = accessibleShops.map((shop) => shop.ShopID )
    //     if(!ShopID) navigate(`/shop/${shopIDs[0]}`)
    //     if(!shopIDs.includes(Number(ShopID))) {
    //         navigate('/')
    //     }
    // }
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const [products, setProducts] = useState(null);
    const [productsFiltered, setProductsFiltered] = useState(null);
    const [likedProducts, setLikedProducts] = useState([]);
    const [invalidShop, setInvalidShop] = useState(false);
    const [searchString, setsearchString] = useState('');
    const [selectedShop, setSelectedShop] = useState(null)
    const [play, setPlay] = useState(false);
    const [loading, setloading] = useState(false);
    const [categoryFilterOptions, setcategoryFilterOptions] = useState([])
    const [categoryFilterValue, setcategoryFilterValue] = useState([]);
    const [manufacturerFilterOptions, setmanufacturerFilterOptions] = useState([])
    const [manufacturerFilterValue, setmanufacturerFilterValue] = useState([])
    const [productGridScrollTargets, setProductGridScrollTargets] = useState([])
    const [shopBannerContent, setShopBannerContent] = useState(null)
    const filtersRef = React.createRef()
    const videoRef = useRef();
    
    const initialStateForUserEnteredParams = () => {
        const currentShopID = ShopID
        const userEnteredParams = JSON.parse(localStorage.getItem('userEnteredParams') || '{}')
        if(!userEnteredParams.shop) {
            setmanufacturerFilterValue([]) 
            setcategoryFilterValue(!!filterCategoryName ? [filterCategoryName] : [])
            setsortValue('') 
            setsearchString('')
            return
        }
        const existingFilters = userEnteredParams.shop.filter(item => Number(item.ShopID) === Number(currentShopID))
        if(!existingFilters || !existingFilters.length) {
            setmanufacturerFilterValue([])
            setcategoryFilterValue(!!filterCategoryName ? [filterCategoryName] : [])
            setsortValue('') 
            setsearchString('')
            return
        }
        setfiltersApplied(true)
        setmanufacturerFilterValue(existingFilters[0].manufacturerFilterValue || [])
        setcategoryFilterValue(!!filterCategoryName ? [filterCategoryName] : existingFilters[0].categoryFilterValue || [])
        setsortValue(existingFilters[0].sortValue || '') 
        setsearchString(existingFilters[0].searchString || '')
    }

    const sortOptions = [
        { value: '', label: ''},
        { value: 1, label: translate('_SHOP_SORT_POINT_LOW_HIGH_LABEL_', 'Point lav - høj')},
        { value: 2, label: translate('_SHOP_SORT_POINT_HIGH_LOW_LABEL_', 'Point høj - lav')},
        { value: 3, label: translate('_SHOP_SORT_ALPHABETIC_A_Z_LABEL_', 'Alfabetisk A-Z')},
        { value: 4, label: translate('_SHOP_SORT_ALPHABETIC_Z_A_LABEL_', 'Alfabetisk Z-A')},
        { value: 5, label: translate('_SHOP_SORT_WITHIN_MY_POINTS_LABEL_', 'Inden for mine point')},
        { value: 6, label: translate('_SHOP_SORT_NEWEST_LABEL_', 'Produkter nyeste - ældste')},
    ]

    const [sortValue, setsortValue] = useState(location && location.state && location.state.sort ? location.state.sort : '')
    const [videoHeight, setVideoHeight] = useState()
    const [imgHeight, setImgHeight] = useState()
    const [shopBanner,setShopBanner] = useState({})
    const [shopVideo, setShopVideo] = useState(false)
    const [shopBannerVideo, setShopBannerVideo] = useState(null)
    const [shopBannerImage, setShopBannerImage] = useState()

    if (location?.state?.scrollTowishlist === true) window.scrollTo(0,0);

    const fetchShopBanner = async() => {
        if(!ShopID) return setShopBannerImage(brandData?.features?.wishlistBannerImageURL)

        let shopbannerResponse = await callAPI(`/shopbanners`, 'GET')
        if (!shopbannerResponse) return
        setShopBanner(shopbannerResponse)
        const langShopBanner = shopbannerResponse.translations?.find(i => i.languageKey === langCode)
        if(langShopBanner?.content){
            setShopBannerContent(langShopBanner.content)
        }else if(shopbannerResponse.content){
            setShopBannerContent(shopbannerResponse.content)
        }
        shopbannerResponse.isVideo ? setShopVideo(true) : setShopVideo(false)
        if (shopbannerResponse.isVideo && shopbannerResponse.videoSrc) {
            return setShopBannerVideo(shopbannerResponse.videoSrc)
        }
        if (!shopbannerResponse.isVideo && shopbannerResponse?.Image) {
            return setShopBannerImage(shopbannerResponse.Image.imageOriginSource)
        }
    }

    const sortAndfilterProducts = () => {
        if(!products) return []
        if(!categoryFilterValue.length && !manufacturerFilterValue.length && !sortValue && !searchString) return products

        let categoryFilteredProducts = (categoryFilterValue.length > 0) 
            ? products.filter(item => item.categories && !!item.categories.length && item.categories.filter(value => categoryFilterValue.includes(value.categoryName)).length)
            : products

        let manufacturerFilteredProducts = (manufacturerFilterValue.length > 0) ? categoryFilteredProducts.filter(item => !!item.manufacturer && manufacturerFilterValue.includes(item.manufacturer.trim())) : categoryFilteredProducts

        let searchResultProducts = (!searchString) ? manufacturerFilteredProducts : manufacturerFilteredProducts.filter(item => searchStringFoundInProduct(item))

        let finalFilteredProducts = searchResultProducts
        if(!sortValue) return finalFilteredProducts
        setProductsFiltered(null)
        return sortProducts(finalFilteredProducts)
    }

    const searchStringFoundInProduct = (product) => {
        if(!searchString || !searchString.trim()) return true

        const searchRegExp = new RegExp(`\\b${searchString}`, 'i')
        
        return ( product.elementContent && product.elementContent.search(searchRegExp) > -1 ) || ( product.elementTitle && product.elementTitle.search(searchRegExp) > -1 ) || ( product.manufacturer && product.manufacturer.search(searchRegExp) > -1 ) || ( product.categories && product.categories.length && product.categories.filter(value => value.categoryName.search(searchRegExp) > -1).length )
    }

    const sortProducts = (productsToBeSorted) => {
        if(!sortValue) return productsToBeSorted
        
        switch (sortValue) {
            case 1:
                return [...productsToBeSorted].sort((a,b) => {
                    let productPointA = a.discountPointComputed ? a.discountPointComputed : a.productPointComputed
                    let productPointB = b.discountPointComputed ? b.discountPointComputed : b.productPointComputed
                    return productPointA > productPointB ? 1 : productPointB > productPointA ? -1 : 0
                })
            case 2:
                return [...productsToBeSorted].sort((a,b) => {
                    let productPointA = a.discountPointComputed ? a.discountPointComputed : a.productPointComputed
                    let productPointB = b.discountPointComputed ? b.discountPointComputed : b.productPointComputed
                    return productPointB > productPointA ? 1 : productPointA > productPointB ? -1 : 0
                })
            case 3:
                return [...productsToBeSorted].sort((a,b) => a.elementTitle.localeCompare(b.elementTitle))
            case 4:
                return [...productsToBeSorted].sort((a,b) => b.elementTitle.localeCompare(a.elementTitle))
            case 5:
                return [...productsToBeSorted].filter(
                (item) =>
                    (item.discountPointComputed
                    ? item.discountPointComputed
                    : item.productPointComputed) <= userData.walletAmountPoints
                );
            case 6:
                return [...productsToBeSorted].sort((a,b) => {
                    let productATime = new Date(a.catalogTimeInsert).getTime()
                    let productBTime = new Date(b.catalogTimeInsert).getTime()
                    return productBTime > productATime ? 1 : productATime > productBTime ? -1 : 0
                })
            default:
            return productsToBeSorted;
        }
        
    }

    const fetchProducts = async () => {
        if(ShopID){
            setInvalidShop(false)
            setloading(true)
            const accessibleShopIDs = accessibleShops.map(item => item.ShopID)
            if (isNaN(ShopID) || !accessibleShopIDs.includes(Number(ShopID))) {
                setInvalidShop(true)
                setloading(false)
                return null
            }
            let shopProducts = await callAPI(`/shop/${ShopID}/products`, "GET");
            let shopPackages = await callAPI(`/shop/${ShopID}/productPackages`, "GET");
            setloading(false)
            if (shopProducts && (!!shopProducts.length || !!shopPackages.length)) {
                shopProducts.forEach(product => {
                    if(product.images && product.images.length){
                        let image = product.images.filter(img => !!img.featureImage)
                        if(image.length){
                            product.imageSrc = image[0]?.imageOptimizedSourceMedium
                        }else{
                            product.images.sort((a,b) => a.poolOrder - b.poolOrder);
                            product.imageSrc = product.images[0]?.imageOptimizedSourceMedium;
                        }
                    }
                });
                if(shopPackages && !!shopPackages.length) {
                    setProducts(shopPackages.map((item) => {
                        return {...item, manufacturer: item.title, elementTitle: item.shortDescription}
                    }).concat(shopProducts))
                } else {
                    setProducts(shopProducts);
                }
                if (filterCategoryName) {
                  setcategoryFilterValue([filterCategoryName]);
                } else {
                //   setProductsFiltered(shopProducts);
                }
                return
            }
            if (shopProducts.length === 0) {
                setProducts([])
                return;
            }
            setProducts(null)
            setProductsFiltered(null)
        }else{
            getWishlistProducts()
        }
    }

    const getWishlistProducts = async() => {
        
        let shopProducts = await callAPI(`product/wishlist`, "GET");
        if (shopProducts && !!shopProducts.length) {
            shopProducts = shopProducts.map(product => {
                if(product.images && product.images.length){
                    product.images.sort((a,b) => a.poolOrder - b.poolOrder);
                    product.imageSrc = product.images[0]?.imageOptimizedSourceMedium;
                }
                // product.categoryNames = product.categories.map(item => item.categoryName).join()
                return product
            });
            setloading(false)
            setProducts(shopProducts)
	        setProductsFiltered(shopProducts)
            return
        }
        if (shopProducts.length === 0) {
            setProducts([])
            return;
            }
        setProducts(null)
	    setProductsFiltered(null)
    }

    const productReaction = async (product) => {
        const ReactionID = product.reaction ? product.reaction.ReactionID : null
        const reaction = await callAPI("/product/reaction", "POST", 
            { body: { ReactionTargetID: product.ProductID, reactionData: JSON.stringify({ ShopID: product.ShopID }), ...ReactionID && { ReactionID } } });
        if(reaction && reaction.affectedRows) {
            fetchProducts()
        }
    }

    const handleChangeCategoryFilterValue = (event) => {
      const { target: { value }} = event;
      // track activity for every new category selection
      if(categoryFilterValue.length <= value.length){
          Activity({activityType: 'filter',activityName: 'shop',activityArgument: value[value.length-1],activityArgumentID: ''})
      }
      const categories = typeof value === 'string' ? value.split(',') : value
      setcategoryFilterValue(categories);
    };
    const handleChangeManufacturerFilterValue = (event) => {
      const { target: { value }} = event;
      setmanufacturerFilterValue(
          typeof value === 'string' ? value.split(',') : value,
      );
    };

    const removeCategory = (item) => {
        var index = categoryFilterValue.indexOf(item);
        let updatedFilter = []
        if (index > -1) {
            updatedFilter = categoryFilterValue.filter(elem => elem !== item);
        }
        setcategoryFilterValue(updatedFilter)
    }
    
    const removeManufacturer = (item) => {
        var index = manufacturerFilterValue.indexOf(item);
        let updatedFilter = []
        if (index > -1) {
            updatedFilter = manufacturerFilterValue.filter(elem => elem !== item);
        }
        setmanufacturerFilterValue(updatedFilter)
    }
    
    const removeAll = () => {
        setcategoryFilterValue([])
        setmanufacturerFilterValue([])
        setsearchString('')
        setsortValue('')
        // if(location && location.state && location.state.sort){
            // location.state.sort = ''
        // }
    }
    const playButton = () => {
        let pause_time = document.getElementById("pause_button_time");
        if (play) {
            videoRef.current.play();
            setPlay(false);
            setTimeout(() => {
            pause_time.style.color = "transparent";
            },5000)
        }
        else {
            videoRef.current.pause();
            pause_time.style.color="white";
            setPlay(true)
        }
    }

    const retainUserEnteredParams = () => {
        const userEnteredParams = JSON.parse(localStorage.getItem('userEnteredParams') || '{}')
        const currentShopParams = {
            ShopID,
            searchString,
            sortValue,
            manufacturerFilterValue: [...manufacturerFilterValue],
            categoryFilterValue: [...categoryFilterValue]
        }
        if(!userEnteredParams.shop || !userEnteredParams.shop.length) {
            userEnteredParams.shop = []
            userEnteredParams.shop.push(currentShopParams)
            return localStorage.setItem('userEnteredParams', JSON.stringify(userEnteredParams))
        }

        const existingFilters = userEnteredParams.shop.filter(item => Number(item.ShopID) === Number(ShopID))
        
        if(!existingFilters || !existingFilters.length) {
            userEnteredParams.shop.push(currentShopParams)
            return localStorage.setItem('userEnteredParams', JSON.stringify(userEnteredParams))
        }
        userEnteredParams.shop = userEnteredParams.shop.filter(item => Number(item.ShopID) !== Number(ShopID))
        userEnteredParams.shop.push(currentShopParams)
        return localStorage.setItem('userEnteredParams', JSON.stringify(userEnteredParams))
    }

    const htmlDecode = (input) => {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    }

    const clearStates = () => {
        setloading(true)
        setShopBannerVideo('')
        setShopBannerImage('')
        setShopBannerContent('')
        setProducts([])
        setProductsFiltered([])
    }

    useEffect(() => {
        let Width = window.innerWidth
        setVideoHeight(Width / 2)
        setImgHeight(Width*5 / 12)
    }, []);

    useEffect(() => {
        if(!productsFiltered) return
        const sections = [
            "shop-banner",
            "shop-selection-menu",
            "shop-filters",
        ]
        if(!productsFiltered.length) {
            return setScrollTargets([...sections, "footer"])
        }
        return setScrollTargets([...sections, ...productGridScrollTargets, "footer"])
        
    },[productsFiltered, productGridScrollTargets])

    useEffect(() => {
        window.addEventListener('resize', _.debounce(() => {
            const videoContainer = document.getElementById('video-div')
            const videoheight = !!videoContainer ? videoContainer.offsetHeight : 650
            setVideoHeight(videoheight)
        }, 200));
        return () => {
            window.removeEventListener('resize', _.debounce(() => {
                const videoContainer = document.getElementById('video-div')
                const videoheight = !!videoContainer ? videoContainer.offsetHeight : 650
                setVideoHeight(videoheight)
            }, 200));
        }
    }, [shopBannerVideo])
    
    useEffect(() => {
        window.addEventListener('resize', _.debounce(() => {
            let imgWidth =  window.innerWidth
            setImgHeight(imgWidth*5 / 12)
        }, 200));
        return () => {
        window.removeEventListener('resize', _.debounce(() => {
            let imgWidth =  window.innerWidth
            setImgHeight(imgWidth*5 / 12)
        }, 200));
        }
    },[shopBannerImage])
        

    useEffect(() => {
        clearStates()
        setSelectedShop(Number(ShopID))
        initialStateForUserEnteredParams()
    }, [ShopID]);

    useEffect(() => {
        fetchShopBanner()
        fetchProducts()
    }, [ShopID, accessibleShops, basket]);

    useEffect(() => {
        if(products && products.length > 0) {
            let liked = []
            let categoryFilterOptions = []
            let manufacturerFilterOptions = []
            products.forEach(element => {
                if(element.reaction && element.reaction.ReactionID && element.reaction.ReactionID > 0) {
                    liked.push(element.ProductID)
                }
                if(element.categories && element.categories.length > 0 ){
                    let categoryOptions = element.categories.filter(item => item.categoryTaxonomy.toUpperCase() === 'PRODUCT').map(item => item.categoryName)
                    categoryFilterOptions.push(...categoryOptions)
                }
                if(element.manufacturer){
                    manufacturerFilterOptions.push(element.manufacturer.trim())
                }
            })
            categoryFilterOptions = [...new Set(categoryFilterOptions)].sort(function (a, b) {
                return a.localeCompare(b); 
            })
            manufacturerFilterOptions = [...new Set(manufacturerFilterOptions)].sort(function (a, b) {
                return a.localeCompare(b); 
            })
            setLikedProducts(liked)
            setcategoryFilterOptions(categoryFilterOptions)
            setmanufacturerFilterOptions(manufacturerFilterOptions)
            if(filterCategoryName){
                setcategoryFilterValue([filterCategoryName])
            }

        }
    }, [products]);

    useEffect(()=>{
        return () => {
            removeAll()
        }
    },[location])

    useEffect(() => {
        setfiltersApplied(manufacturerFilterValue.length > 0 || categoryFilterValue.length > 0 ? true : false)
        const productsForGrid = sortAndfilterProducts()
        const scrollToProductIds = []
        productsForGrid.forEach((e,idx, arr) => {
            if(idx % 8) return arr[idx].htmlId = ""
            const id = `shop-product-${idx}`
            scrollToProductIds.push(id)
            return arr[idx].htmlId = id
        });
        setProductGridScrollTargets([...scrollToProductIds])
        setProductsFiltered([...productsForGrid])
        retainUserEnteredParams()
        const scrollTo = Number(sessionStorage.getItem('scrollPosition')) || 0
        if(!scrollTo || scrollTo > document.body.clientHeight) return

        window.scrollTo( 0, scrollTo);
        sessionStorage.removeItem('scrollPosition')
    }, [categoryFilterValue, manufacturerFilterValue, sortValue, searchString, products, document.body.clientHeight]);

    const bannerStyle1 = { display: 'flex', width: '100%', height:!!shopVideo ? videoHeight : null,flexDirection: 'column', maxHeight: styleGuide.bannerMaxHeight}
    const bannerStyle = defaultTheme == 'ThemeONE' ? { ...bannerStyle1 } : defaultTheme == 'ThemeTWO' ? { ...bannerStyle1, marginTop: '-180px' } : { ...bannerStyle1,marginTop: '-20px' };
    
    return !!invalidShop ? null : (
        <>
            <div id="shop-banner" style={bannerStyle}>
               <div>
                {
                    (!!shopBannerVideo && shopVideo)
                            ? <>
                                <div onClick={playButton}>
                                <div style={{height:videoHeight,overflow: 'hidden',position:'absolute',left:0,right:0, maxHeight: styleGuide.bannerMaxHeight}}>
                                <video 
                                    id='video-div' 
                                    playsInline
                                    autoPlay
                                    loop
                                    muted
                                    preload="metadata"
                                    ref={videoRef}
                                    style={{  
                                        objectFit:'cover',
                                        width:'100%',
                                        maxHeight: styleGuide.bannerMaxHeight,
                                        }} >
                                        <source src={shopBannerVideo} />
                                </video>
                                    </div>  
                                    <div style={{height:videoHeight,maxHeight:styleGuide.bannerMaxHeight,marginTop:defaultTheme == 'ThemeTWO'?'-70px':null}}id="pause_button_time" className={`${defaultTheme}play_pause_button_div play_pause_div`} onClick={playButton}>
                                        <div id="play_pause" style={{ position: "absolute" }}><h1>{play ? <Playbutton style={{height:'100%',width:'100%'}} /> : <Pausebutton style={{height:'100%',width:'100%'}}/>}</h1>
                                    </div>
                                        <div id={`${defaultTheme}shop_banner_text`} style={{position:"absolute",zIndex:1,left:0}} >
                                        <div className="shopBannerTitle">
                                        <span
                                            style={{
                                                fontFamily: styleGuide.fontFamily,
                                                fontStyle: 'normal',
                                                color: shopBanner.textColor || 'black'
                                            }}
                                            className={classes.shopHeadline}
                                            dangerouslySetInnerHTML={{ __html: htmlDecode(!ShopID ? translate('_WISHLIST_BANNER_HEADLINE_','Ønskeliste') : translate('_SHOP_BANNER_HEADLINE_',
                                            shopBanner.translations?.length 
                                            ? shopBanner.translations.find(l => l.languageKey === langCode)?.title
                                            : shopBanner.title ||'Produkter'
                                            ))}}
                                            >
                                        </span>
                                        </div>
                                            {!isNaN(ShopID) && !!shopBannerContent && <div className="shopBannerContent">
                                                <span
                                                    style={{
                                                        fontStyle: 'normal',
                                                        color: shopBanner.textColor || 'black',
                                                    }}
                                                    className={classes.shopContent}
                                                    dangerouslySetInnerHTML={{
                                                        __html: htmlDecode(translate('_SHOP_BANNER_CONTENT_', shopBannerContent))
                                                    }}
                                                >
                                                </span>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                        </>
                            :
                            <div style={{ width:'100%', overflow: 'hidden', position:'absolute', left:0, right:0, maxHeight: styleGuide.bannerMaxHeight}}>
                            <Image
                            src={`url(${shopBannerImage})`}
                            style={{
                                width: '100%',
                                paddingTop:'calc(5/12*100%)',
                                backgroundSize: "cover",
                                backgroundPosition: "center"
                            }}
                        >
                            <div style={{
                                position: 'absolute',
                                bottom: imgHeight / 15,
                            }}>
                                <div className="shopBannerTitle">
                                <span
                                    style={{
                                        fontFamily: styleGuide.fontFamily,
                                        fontStyle: 'normal',
                                        color: shopBanner.textColor || 'black'
                                    }}
                                    className={classes.shopHeadline}
                                    dangerouslySetInnerHTML={{
                                        __html: htmlDecode(!ShopID ? translate('_WISHLIST_BANNER_HEADLINE_','Ønskeliste') : translate('_SHOP_BANNER_HEADLINE_', 
                                        shopBanner.translations?.length 
                                            ? shopBanner.translations.find(l => l.languageKey === langCode)?.title
                                            : shopBanner.title ||'Produkter'))}}
                                    >
                                </span>
                                </div>
                                    {!!shopBannerContent && <div className="shopBannerContent">
                                        <span
                                            style={{
                                                fontStyle: 'normal',
                                                color: shopBanner.textColor || 'black',
                                            }}
                                            className={classes.shopContent}
                                            dangerouslySetInnerHTML={{
                                                __html: htmlDecode(translate('_SHOP_BANNER_CONTENT_', shopBannerContent))
                                            }}
                                        >
                                        </span>
                                    </div>}
                                </div>
                                </Image>
                                </div>
                    }
                </div>
            </div> 
            <div style={{marginTop: !shopVideo ? styleGuide.bannerMaxHeight : null}}>
                {!isNaN(ShopID) && accessibleShops.length > 1 && 
                                <div id="shop-selection-menu" style={{height:'50px'}}>
                                <div style={{width: '100%',position:'absolute',left:0,background: styleGuide.color18, display: 'flex', padding: '20px', height: 50, justifyContent: "center"}}>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 50, alignItems: "center" }}>
                                        {accessibleShops.map((shop)=>{
                                            return <div key={shop.ShopID} style={{ color: "#ffffff", cursor: "pointer", fontWeight: selectedShop === shop.ShopID ? "bold" : "normal", textTransform: 'capitalize' }} onClick={()=>
                                            {
                                                setProducts(null)
                                                navigate(`/shop/${shop.ShopID}`)
                                            }}>{shop.moduleName}</div>
                                        })}
                                    </div>
                    </div>
                    </div>
                }
            </div>
            <div style={{marginTop:'70px'}} >
                <div style={{display: 'flex', flexDirection: 'row' }} >
                {
                    (products ===null || loading)
                ?   <div className="shop-content" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
                            <CircularIndeterminate size={100}/>
                        </div>
                    </div>
                :   (products.length === 0)
                ?   <div className="shop-content" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
                            <Headline5 translationKey="_SHOP_FILTER_NO_RESULTS_CAPTION_" fallbackText="Ingen resultater" />
                            <ContentNormal translationKey="_SHOP_FILTER_NO_RESULTS_TEXT_" fallbackText="Vi fandt ingen resultater" />
                        </div>
                    </div>
                :
                    <div ref={filtersRef} className="shop-content" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid id="shop-filters" container style={{justifyContent: 'space-evenly'}}>
                            <Grid item xs={12} sm={6} sm2={3}>
                                {
                                    !!categoryFilterOptions && products && products.length && (
                                        <MultipleSelectCheckmarks 
                                            options={categoryFilterOptions}
                                            label={translate('_SHOP_CATEGORY_FILTER_LABEL_', 'Kategorier')} 
                                            wrapperStyle={{marginBottom: 10, padding: '1rem'}}
                                            value={categoryFilterValue}
                                            onChange={handleChangeCategoryFilterValue}
                                            renderValue={() => categoryFilterValue.join(', ')}
                                            emptyValue={categoryFilterOptions.length > 0 ? null : {text: translate('_SHOP_NO_CATEGORY_OPTIONS_LABEL_', 'Ingen kategorier')}}
                                        />
                                    )
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} sm2={3}>
                                {
                                    !!manufacturerFilterOptions && products && products.length && (
                                        <MultipleSelectCheckmarks 
                                            options={manufacturerFilterOptions}
                                            label={translate('_SHOP_MANUFACTURER_FILTER_LABEL_', 'Brand')} 
                                            wrapperStyle={{marginBottom: 10, padding: '1rem'}}
                                            value={manufacturerFilterValue}
                                            onChange={handleChangeManufacturerFilterValue}
                                            renderValue={() => manufacturerFilterValue.join(', ')}
                                            emptyValue={manufacturerFilterOptions.length > 0 ? null : {text: translate('_SHOP_NO_MANUFACTURER_OPTIONS_LABEL_', 'Ingen brands')}}
                                        />
                                    )
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} sm2={3}>
                                {
                                    (!!products && !!products.length) && 
                                    <CustomSelect 
                                        wrapperStyle={{marginBottom: 10, padding: '1rem'}}
                                        label={translate('_SHOP_SORT_FILTER_LABEL_', 'Sortér')} 
                                        value={sortValue} 
                                        onChange={(e) => {
                                            setsortValue(e.target.value)
                                            }
                                        }
                                        options={sortOptions}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} sm2={3}>
                                {
                                    (!!products && !!products.length) && 
                                        <CustomInput
                                            autoFocus={true}
                                            wrapperStyle={{marginBottom: 10, padding: '1rem'}}
                                            label={translate('_SHOP_SEARCH_LABEL_', 'Søg')}
                                            value={searchString} 
                                            onChange={(e) => setsearchString(e.target.value || '')}
                                            customIcon={<Search fill={styleGuide.color7}/>}
                                        />
                                }
                            </Grid>
                        </Grid>
                            
                            {
                                filtersApplied || !!searchString.trim()
                                    ?   <FiltersApplied 
                                            categoryFilterValue={categoryFilterValue} 
                                            manufacturerFilterValue={manufacturerFilterValue} 
                                            removeCategory={removeCategory}
                                            removeManufacturer={removeManufacturer}
                                            removeAll={removeAll}
                                            searchString={searchString}
                                            productsFiltered={productsFiltered}
                                            filtersApplied={filtersApplied}
                                        />
                                    :   null
                            }
                            {
                                (!productsFiltered || !productsFiltered.length) && !!filtersApplied && !searchString.trim() && 
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
                                    <Headline5 translationKey="_SHOP_FILTER_NO_RESULTS_CAPTION_" fallbackText="Ingen resultater" />
                                    <ContentNormal translationKey="_SHOP_FILTER_NO_RESULTS_TEXT_" fallbackText="Vi fandt ingen resultater" />
                                </div>
                            }
                            {
                                (!productsFiltered || !productsFiltered.length) && (!!searchString && !!searchString.trim() && !!filtersApplied) && 
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <Headline5 translationKey="_SHOP_SEARCH_NO_RESULTS_CAPTION_" fallbackText="Ingen resultater for" />
                                        <span>&nbsp;</span>
                                        <Headline5 fallbackText={` “${searchString}”`} />
                                    </div>
                                    <ContentNormal translationKey="_SHOP_SEARCH_NO_RESULTS_TEXT_" fallbackText="Vi fandt ingen resultater, der matcher din søgning. Søg igen eller brug filteringsmulighederne." />
                                </div>
                            }
                            {
                                (!!productsFiltered && !!productsFiltered.length) && !!filtersApplied && !!searchString && !!searchString.trim() && 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '3rem'}}>
                                    <Headline5 fallbackText={`${productsFiltered.length} `} />
                                    <span>&nbsp;</span>
                                    <Headline5 translationKey="_SHOP_SEARCH_RESULTS_CAPTION_" fallbackText="resultat for" />
                                    <span>&nbsp;</span>
                                    <Headline5 fallbackText={` “${searchString}”`} />
                                </div>
                            }
                            {
                                (!!productsFiltered && !!productsFiltered.length) && <Grid id="shop-products" className="product-grid" container rowSpacing={1} columnSpacing={1}>
                                    {
                                        productsFiltered.map((item, idx) =>
                                            <Grid id={item.htmlId || ""} key={idx} item xs={12} sm={6} sm2={3}>
                                                <ProductNormal
                                                    storeScrollPosition={true}
                                                    productID={item.ProductID}
                                                    packageID={item.ProductPackageID}
                                                    showTag={item.enableDiscount ? true : false}
                                                    tagOptions={{
                                                        fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${pointsConvertor(brandData,item.productPointComputed - item.discountPointComputed, pointSuffix)}`,
                                                        showClose: false,
                                                    }}
                                                    tagStyles={{borderRadius: 8}}
                                                    sticker={item.flag && item.flag !== 'STICKY' ? item.flag : null}
                                                    points={(item.enableMulti && item.minCount > 0) 
                                                        ? `${item.discountPointComputed ? item.minCount * item.discountPointComputed : item.minCount * item.productPointComputed}`
                                                        : `${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`}  
                                                    liked={ShopID ? likedProducts.includes(item.ProductID || item.ProductPackageID) ? true : false : true}
                                                    className="productlarge item"
                                                    style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
                                                    text2={item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')}
                                                    text1={item.manufacturer}
                                                    wishlistCallback={() => productReaction(item)}
                                                    imageSrc={`url(${item.imageSrc || item.image?.imageOptimizedSourceMedium})`}
                                                    activityName='shop'
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            }
                    </div>
                }
                </div>
                {/* {!!productsFiltered && !!productsFiltered.length && <ScrollButton filtersReference={filtersRef}/>} */}
                <div style={{marginTop: 64}}>
                    <DiscountedProductsSection/>
                </div>
            </div>
            <ScrollButton scrollTargets={scrollTargets} />
        </>
    )
}

export default Shop

const FiltersApplied = (props) => {
    const {categoryFilterValue, manufacturerFilterValue, removeCategory, removeManufacturer, removeAll, searchString, productsFiltered, filtersApplied} = props
    const [filtersRefresh, setFilterRefresh] = useState(null);
    useEffect(() => {
        setFilterRefresh(Date.now())
    }, [categoryFilterValue, manufacturerFilterValue]);
    return <>
    <Grid container style={{marginTop: 30, padding: '1rem'}}>
        {   // filters applied
            ( (!!categoryFilterValue && categoryFilterValue.length > 0) || (!!manufacturerFilterValue && manufacturerFilterValue.length > 0) ) &&
            <Grid item xs={12} sm={10} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center',}}>
                {
                    <CaptionText style={{marginRight: 16, marginTop: 10}} translationKey="_SHOP_FILTERS_APPLIED_LABEL_" fallbackText="Valgte filtre" />
                }
                { 
                    !!categoryFilterValue && categoryFilterValue.length > 0 && categoryFilterValue.map( 
                        (item, idx) =>
                        <LabelTag2 key={idx} closeHandler={() => removeCategory(item)} showIcon={false} style={{marginRight: 6, borderRadius: 8, marginTop: 10, cursor: 'pointer'}} fallbackText={item} />
                    )
                }
                { 
                    !!manufacturerFilterValue && manufacturerFilterValue.length > 0 && manufacturerFilterValue.map(
                        (item, idx) =>
                        <LabelTag2 key={idx} closeHandler={() => removeManufacturer(item)} showIcon={false} style={{marginRight: 6, borderRadius: 8, marginTop: 10, cursor: 'pointer'}} fallbackText={item} />
                    )
                }
            </Grid>
        }
        {   // search only + results
            !filtersApplied && 
            !!searchString && !!searchString.trim() && 
            productsFiltered && productsFiltered.length > 0 && 
            // <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '3rem'}}>
            <Grid item xs={12} sm={10} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent: 'center'}}>
                <Headline5 fallbackText={`${productsFiltered.length} `} />
                <span>&nbsp;</span>
                <Headline5 translationKey="_SHOP_SEARCH_RESULTS_CAPTION_" fallbackText="resultat for" />
                <span>&nbsp;</span>
                <Headline5 fallbackText={` “${searchString}”`} />
            </Grid>
        }
        {   // search only + no results
            (!productsFiltered || !productsFiltered.length) && 
            (!!searchString && !!searchString.trim()) && 
            !filtersApplied && 
            // <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem'}}>
            <Grid item xs={12} sm={10} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Headline5 translationKey="_SHOP_SEARCH_NO_RESULTS_CAPTION_" fallbackText="Ingen resultater for" />
                    <span>&nbsp;</span>
                    <Headline5 fallbackText={` “${searchString}”`} />
                </div>
                <ContentNormal translationKey="_SHOP_SEARCH_NO_RESULTS_TEXT_" fallbackText="Vi fandt ingen resultater, der matcher din søgning. Søg igen eller brug filteringsmulighederne." />
            </Grid>
        }
        <Grid item xs={12} sm={2} className="resetFilterButton" style={{flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
            <ButtonMedium4 onClick={() => removeAll()} style={{marginTop: 10}} showIcon={false} translationKey="_SHOP_RESET_FILTERS_LABEL_" fallbackText="Ryd alt" />
        </Grid>
    </Grid>
    </> 
}

const DiscountedProductsSection = (props) => {
    const { styleGuide } = useContext(AppContext)
    const [discountedProducts, setDiscountedProducts] = useState([])
    const classes = jss(styleGuide)
    const navigate = useNavigate()
    const { ShopID } = useParams()
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const suggestedProductsStyle = {
      position: "relative",
      marginTop: 100, 
            marginBottom: -100, 
            paddingTop: 100, 
            paddingBottom: 100, 
            backgroundColor: styleGuide.color3,
    };

    const fetchDiscountedProducts = async() => {
        if(ShopID){
            let discountedProducts = await callAPI(`/shop/${ShopID}/productsOnDiscount`, "GET");
            if (discountedProducts && !!discountedProducts.length) {
                discountedProducts.forEach(product => {
                    if(product.images && product.images.length){
                        product.images.sort((a,b) => a.poolOrder - b.poolOrder);
                        product.imageSrc = product.images[0]?.imageOptimizedSourceMedium;
                    }
                });
                setDiscountedProducts(discountedProducts)
                return
            }            
        }
    }

    useEffect(() => {
        fetchDiscountedProducts()
    },[])

    const  productReaction = async (product) =>{
      const ReactionID = product.reaction ?  product.reaction.ReactionID: null
      let reaction = await callAPI("/product/reaction", "POST",{body: {ReactionTargetID:product.ProductID,reactionData:JSON.stringify({ShopID:product.ShopID}),...ReactionID && {ReactionID}}});
      fetchDiscountedProducts()
    }
    return !discountedProducts.length ? null : (
      <div className="horizontal-scroll-wrapper products-on-discount" style={suggestedProductsStyle}>
          <HorizontalScrollBlock caption={translate("_SHOP_DISCOUNTED_PRODUCTS_CAPTION_",'Nedsatte produkter')}>
            {discountedProducts.map((item) => (
              <ProductNormal
                productID={item.ProductID}
                packageID={item.ProductPackageID}
                key={item.ProductID || item.ProductPackageID}
                showTag={item.enableDiscount ? true : false}
                tagOptions={{
                    fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${item.productPointComputed - item.discountPointComputed} ${pointSuffix}`,
                    showClose: false,
                }}
                tagStyles={{borderRadius: 8}}
                points={(item.enableMulti && item.minCount > 0) 
                    ? `${item.discountPointComputed ? item.minCount * item.discountPointComputed : item.minCount * item.productPointComputed}` 
                    : `${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`
                } 
                liked={item.reaction && item.reaction.ReactionID ? true : false }
                className="productlarge item"
                style={{ boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)` }}
                text2={item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')}
                text1={item.manufacturer}
                wishlistCallback={() => productReaction(item)}
                imageSrc={`url(${item.imageSrc || item.image?.imageOptimizedSourceMedium})`}
                activityName='shop'
              />
            ))}
          </HorizontalScrollBlock>
        <div className={classes.seeMoreButton}>
          <ButtonMedium4
                    style={{ margin: "auto" }}
                    showIcon={false}
                    translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
                    // onClick={ShopID ? () => { navigate(`/shop/${ShopID}`) } : null}
                    onClick={() => { window.scrollTo(0, 0) }}
          >
            Se alle produkter
          </ButtonMedium4>
        </div>
      </div>
    );
  };

const ScrollButtonOld = (props) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled >= 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);
  return (
    <>
      {visible && (
        <div
          style={{
            position: "fixed",
            bottom: "0.5rem",
            right: "0.5rem",
            cursor: "pointer",
            zIndex: 9999,
          }}
        >
          <Col sm={12} style={{ marginBottom: "15px" }}>
            <span
              onClick={() => {
                props.filtersReference.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              <FilterIcon style={{ cursor: "pointer" }} />
            </span>
          </Col>
          <Col sm={12}>
            <span onClick={scrollToTop}>
              <ArrowTopWithCircle style={{ cursor: "pointer" }} />
            </span>
          </Col>
        </div>
      )}
    </>
  );
};
    