import { AppContext } from "contexts/AppContext"
import { useContext, useEffect } from "react"
import { Navigate } from "react-router-dom"

const Logout = (props) => {
    const {setUserData, setMenuData, userData} = useContext(AppContext)
    useEffect(() => {
        const token = localStorage.getItem('accessToken')
        if(userData && userData.UserID && !token) {
            setUserData(null)
        }
    }, []);

    return <Navigate to="/login" />;
}
export default Logout 