const Plus = (props) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return <>
        <svg style={props.style} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33317 4.47621H4.47603V7.33335H3.52365V4.47621H0.666504V3.52383H3.52365V0.666687H4.47603V3.52383H7.33317V4.47621Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth} />
        </svg>
    </>
}
export default Plus
